$path: "/fonts/";
$fonts: "Montserrat";
$weights: (
  "Thin": 100,
  "ExtraLight": 200,
  "Light": 300,
  "Regular": 400,
  "Medium": 500,
  "SemiBold": 600,
  "Bold": 700,
  "ExtraBold": 800,
);

@each $font in $fonts {
  @each $name, $weight in $weights {

    @font-face {
      font-family: $font;
      src: url(#{$path}#{$font}-#{$name}.ttf);
      font-weight: $weight;
    }
  }
}