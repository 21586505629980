@tailwind base;
@tailwind components;
@tailwind utilities;

@import "loader";
@import "contextify";
@import "fonts";

html,
body,
#root {
  height: 100%;
  height: 100vh;
  height: 100dvh;
}

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  @apply bg-gray-950;
  @apply text-gray-50;
  @apply scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-700 scrollbar-corner-transparent;
}

.message {
  @apply mx-1;

  &.message-has-tail {
    &:after {
      content: "";
      @apply absolute bottom-0;
      @apply [border-width:theme("size.1")];
      @apply w-0 h-0;
    }

    &.message-own {
      &:after {
        border-color: transparent transparent currentColor currentColor;
        transform: translateX(100%);
        @apply right-0;
      }
    }

    &.message-other {
      &:after {
        border-color: transparent currentColor currentColor transparent;
        transform: translateX(-100%);
        @apply left-0;
      }
    }
  }
}