.loader {
  --size: theme("size.16");
  @apply w-[var(--size)] h-[var(--size)];
  @apply inline-flex items-center justify-center;
  @apply relative;

  &:after,
  &:before {
    @apply content-[""];
    @apply w-full h-full;
    @apply rounded-full;
    background: theme("colors.primary");
    @apply absolute;
    @apply opacity-0;
    animation: animloader 2s linear infinite;
  }

  &:after {
    animation-delay: 1s;
  }
}

@keyframes animloader {
  0% {
    transform: scale(0);
    @apply opacity-100;
  }

  100% {
    transform: scale(1);
    @apply opacity-0;
  }
}