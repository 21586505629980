:root {
  --contexify-zIndex: 666;
  --contexify-menu-minWidth: 220px;
  --contexify-menu-padding: theme("spacing.1");
  --contexify-menu-radius: theme("borderRadius.md");
  --contexify-menu-bgColor: theme("colors.gray.600");
  --contexify-menu-shadow: none;
  --contexify-menu-negatePadding: var(--contexify-menu-padding);

  --contexify-separator-color: theme("colors.gray.400");
  --contexify-separator-margin: theme("spacing.2");
  --contexify-itemContent-padding: theme("spacing.1");
  --contexify-activeItem-radius: theme("borderRadius.sm");
  --contexify-item-color: theme("colors.gray.50");
  --contexify-activeItem-color: theme("colors.gray.50");
  --contexify-activeItem-bgColor: theme("colors.gray.600");
  --contexify-rightSlot-color: blue;
  --contexify-activeRightSlot-color: red;
  --contexify-arrow-color: yellow;
  --contexify-activeArrow-color: orange;
}

.contexify {

  @apply overflow-hidden;

  .contexify__submenu--is-open,
  .contexify__submenu--is-open>.contexify__item__content {}

  .contexify__submenu--is-open>.contexify__submenu {}

  .contexify .contexify__submenu {}

  .contexify__submenu-arrow {}

  .contexify__separator {}

  .contexify__will-leave--disabled {}

  .contexify_item {

    .contexify_itemContent {
      @apply transition-colors duration-300;
      @apply text-sm;
      @apply transition-all duration-300;
      @apply p-2;
      @apply rounded;
      @apply flex gap-4 items-center opacity-100;
    }

    &:not(.contexify__item--disabled):focus {}

    &:not(.contexify_item--disabled):hover>.contexify_itemContent,
    &:not(.contexify_item--disabled):focus>.contexify_itemContent {
      @apply gap-5;
      @apply bg-opacity-10 bg-white opacity-100;
    }

    &:not(.contexify__item--disabled):hover>.contexify__submenu {}
  }

  .contexify__item--disabled {}
}